:root {
  --mainColor: black;
  --chat-header-bg-color: #18171c;
  --chat-bg-color:#18171c;
  --message-user-bg-color: grey;
  --message-bot-bg-color: #2977b6;
  --image-button-bg-color: #009688;
  --header-title-color: white;
  --button-options-background:#d3d0d0;
  --font-size: medium;
  --font-type:italic;
  --input-container-bg: #44424e; 
  --header-fontWeight: bold;
  --full-width: 100vw;
  --full-height: 93%;
}

.fullscreen-chat-container {
  position: fixed;
  top: 0;
  right: 0;
  width: var(--full-width);
  height: var(--full-height);
  border-radius: 0; 
}
.loading-indicator {
  position: absolute; 
  left: 2;
}
.chat-window.fullscreen {
  width: var(--full-width);
  height: var(--full-height);
  border-radius: 0; 
}

.chat-container {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: var(--font-size);
  font-weight: var(--font-type);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 23px 23px 0 0;
  padding: 15px;
  background-color: var(--chat-header-bg-color);
}

.header-section {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image {
  max-height: 50px;
  max-width: 50px;
  margin-left:-50%;
}



.header-title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: var(--header-fontWeight);
  color: var(--header-title-color);
  margin-left: -10%;
}

.menu {
  margin-right: -10%;
  position: relative;
  display: flex;
  gap:5px;
}

.menu-options, .menu-options-fullscreen {
  display: none;
  position: absolute;
  top: 100%;  
  width: 150px;
  background-color: transparent; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
  z-index: 1000;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  border-radius: 5px;
}

.menu-options.show, .menu-options-fullscreen.show {
  display: flex;
}
.menu-button, .fullscreen-button {
  font-size: 16px;
  color: var(--mainColor);
  padding: 15px 15px;
  line-height: 1;
  
}

.back-button, .reset-button, .guardar-logs {
  display: block;
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #d3d0d0;
  color: var(--mainColor);
  border: none;
  border-radius: 20px;
  margin-top: 5px;
}
.chat-window.fullscreen .message {
  font-size: 1.25em;
}

.menu-button:hover, .fullscreen-button:hover, .back-button:hover, .reset-button:hover, .guardar-logs:hover {
  background-color: #ececec; 
}

.send-button:hover, .upload-button:hover, .cancel-button:hover {
  background-color: var(--image-button-bg-color); 
  color: white; 
}

.reset-button {
  background-color: var(--button-options-background);
}

.back-button {
  background-color: var(--button-options-background);
}

.guardar-logs {
  background-color: var(--button-options-background);
}

.chat-button {
  border-radius: 70%;
  width: 70px;
  height: 70px;
  background-color: var(--image-button-bg-color);
  color: white;
  border: none;
}

.chat-window {
  position: fixed;
  right: 0;
  top: 0;
  width: 500px;
  height: 70%; 
  border-radius: 23px;
  box-sizing: border-box;
  transition: width 0.3s ease, height 0.3s ease; 
  box-shadow: 0 4px 6px rgba(0,0,0,0.1)
}

.message {
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-container {
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--chat-bg-color);
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 60px);
}

.message {
  position: relative;
  border-radius: 7.5px;
  padding: 10px;
  margin-bottom: 20px; 
  max-width: 80%;
  word-wrap: break-word;
  text-align: left;
  color: white;
  display: flex; 
  flex-direction: column; 
}

.user-message {
  align-self: flex-end;
}

.bot-message {
  align-self: flex-start;
}

.user-message .message-text,
.bot-message .message-text {
  margin-top: 50px;
  background-color: var(--message-user-bg-color);
  padding: 10px;
  border-radius: 10px;
}

.bot-message .message-text {
  background-color: var(--message-bot-bg-color);
}

.user-message .message-image,
.bot-message .message-image {
  position: absolute;
  top: 10px; 
  max-height: 40px;
  max-width: 40px;
}

.user-message .message-image {
  right: 10px;
}

.bot-message .message-image {
  left: 10px; 
}

.input-container, .chat-header {
  width: 100%; 
}

.input-container {
  position: absolute;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius:  0 0 23px 23px;
  padding: 5px;
  padding-bottom: 5px;
  background-color: var(--input-container-bg); 
}

.input-container .text-input {
  border-radius: 23px;
  width: 70%;
}


.text-input {
  text-align:left;
  padding: 5px;
  -webkit-box-sizing: border-box;
  font-size: 14px;
}

.send-button, .cancel-button {
  width: 50px;
  padding: 5px 10px;
  border-radius: 23px;
  color: var(--mainColor); 
  border: none;
}

.upload-button {
  width:50px;
  padding: 5px;
  color: var(--mainColor);
  background-color: initial;
  border-radius: 50px;
}

.upload-button.selected {
  border-radius: 50px;
  background-color: var(--image-button-bg-color);
}

.file-input {
  display: none;
}
@media screen and (max-width: 600px) {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    font-size: 16px; 
  }
  :root {
    --font-size: small;
    --header-title-font-size: 16px;
  }

  
  .chat-window,
  .fullscreen-chat-container {
    width: var(--full-width);
    height: var(--full-height);
    border-radius: 0;
    margin: 0;
  }

  .header-title {
    font-size: var(--header-title-font-size);
    margin-left: 0;
  }

  .chat-header, .input-container {
    padding: 10px;
    border-radius: 0;
  }

  .fullscreen-button {
    display: none;
  }

  .menu-button, .back-button, .reset-button, .guardar-logs {
    width: 70%;
    padding: 10px 10px;
    font-size: 14px;
  }

  .menu-button:hover, .fullscreen-button:hover, .back-button:hover, .reset-button:hover, .guardar-logs:hover, .upload-button {
    background-color: initial; 
  }

  .chat-button {
    width: 50px;
    height: 50px;
  }

  .message {
    font-size: 16px;
    max-width: 100%; 
    margin-bottom: 15px;
  }

  .user-message .message-text, .bot-message .message-text {
    margin-top: 25px;
    padding: 8px;
    border-radius: 7px; 
  }

  .user-message .message-image,
  .bot-message .message-image {
    top: 0px; 
    max-height: 30px;
    max-width: 30px;
  }
  
  .user-message .message-image {
    right: 5px;
  }

  .bot-message .message-image {
    left: 5px;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
  }

  .text-input, .upload-button, .send-button, .cancel-button {
    width: auto;
  }
}

.fullscreen-no-radius {
  border-radius: 0 !important;
} 
.fullscreen-chat-container {
  position: fixed;
  top: 0;
  right: 0;
  width: var(--full-width);
  height: var(--full-height);
  border-radius: 0;
}